<template>
  <div style="position: absolute; top:0; bottom: 64px; width: 100%; ">
    <v-card flat class="custom-variance-card" style="height: 100%; overflow-y: auto;">
      <v-container v-if="isLoading" fill-height>
        <v-row class="mx-0" v-if="isLoading">
          <v-col md="12" class="text-center">
              <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <div v-else>
        <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
          Please choose a specific date within the set date variance to generate accurate and relevant results. The date variance determines the range of dates around the selected date that will be considered for generating the results. By selecting a date within this range, you can ensure that the results align with your desired timeframe and provide the most up-to-date information.
        </div>
        <hb-form 
          class="hb-text-night custom-variance-form" 
          label="Reference Date for Variance"
          description="Please select a date to generate results based on the set date variance."
          >
          <v-row class="mb-10 ml-0">
            <v-col cols="7" class="pa-0">
              <HbCheckbox
                v-model="isVariance"
                label="Variance is calculated from"
              />
            </v-col>
            <v-col cols="5" class="pa-0 date-field">
              <v-menu
                v-model="menu"
                :nudge-right="40"
                transition="scale-transition"
                :close-on-content-click="false"
                y-offset
              >
                <template v-slot:activator="{ on, attrs }">
                  <hb-text-field
                    class="pt-0 mt-0"
                    v-model="formattedDate"
                    placeholder="Select Date"
                    prepend-inner-icon="mdi-calendar-blank"
                    @click:prepend-inner="menu = true"
                    v-validate=" isVariance ? 'required' : ''"
                    append-icon="mdi-menu-down"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="() => (date = '')"
                    readonly
                    dense
                    :clearable="false"
                    :disabled="!isVariance"
                    width="210px"
                    data-vv-name="date"
                    data-vv-as="Date"
                    :error="errors.collect('date').length > 0"
                  ></hb-text-field>
                </template>
                <v-date-picker
                  scrollable
                  no-title
                  header-color="#00848E"
                  color="#00848E"
                  width="220"
                  :max="nowDate"
                  v-model="date"
                  :type="'date'"
                  :show-current="false"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </hb-form>
      </div>
    </v-card>
    <hb-bottom-action-bar @close="$emit('cancel')" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn 
          color="primary"
          @click="saveCustomVariance"
          :disabled="isLoading"
        >
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import moment from "moment";
import api from '../../../assets/api.js';
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name:"CustomVariance",
  props: ['report','property'],
  mixins: [notificationMixin],
  data() {
    return {
      nowDate: new Date().toISOString().slice(0,10),
      isVariance: false,
      date: '',
      menu: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
        geDynamicRunParam: 'reportStore/geDynamicRunParam',
        getCustomVariance: 'reportStore/getCustomVariance'
    }),
    formattedDate: {
      get() {
        const date = this.date ?? "";
        return date ? moment(date, "YYYY-MM-DD").format("MMM DD, YYYY") : "";
      },
      set(value) {
        this.date = value ?? "";
      },
    },
  },
  created() {
    this.isVariance = this.getCustomVariance?.active
    this.date = this.getCustomVariance?.date
  },
  methods: {
    ...mapActions({
      updateCustomVariance:'reportStore/updateCustomVariance',
    }),
    async saveCustomVariance() {
      const valid = await this.$validator.validateAll();
      if(valid) {

        let url = api.getPropertyRentManagementUrl(this.property) + "custom-variance";
        let body = { 
          active: !!this.isVariance,
          ...(this.isVariance ? {date: this.date}: {})
        }
        await api
          .put(this, url, body)
          .then(() => {
            this.showMessageNotification({
              type: "success",
              description: `The Custom Variance has been successfully applied for this property.`,
              timeout: 3000,
            });
            this.updateCustomVariance({ active: !!this.isVariance, date: this.date })
            EventBus.$emit("reviewRentChangeEvents", { event: 'refreshTable'})
            this.$emit('cancel')
          })
          .catch((error) => {
            this.showMessageNotification({
              type: "error",
              description: error,
            });
          });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.custom-variance-form {
  .date-field {
    margin-left: -7px ;
  }
}
</style>