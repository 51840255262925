<script type="text/babel">
import { EventBus } from "../../EventBus.js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "SpaceGroupActions",
  props: {
    propertyId: {
      type: String,
      default: ''
    }
  },
  computed: {
    items() {
      return [
        {
          text: "View Spaces",
          event: "viewSpaces",
        },
        {
          text: "Edit Set Rate",
          event: "editSetRate",
        },
        {
          text: "Edit Sell Rate",
          event: "editSellRate",
        },
      ];
    },
    ...mapGetters({
      spaceData: "revManStore/getSpaceData",
    }),
  },
  methods: {
    ...mapMutations({
      setSpaceData: "revManStore/SET_SPACE_DATA",
      setPrices: "revManStore/SET_PRICES",
    }),
    triggerEvent(event) {
      if (event == "viewSpaces") {
        this.setSpaceData(
          {
            ...this.params.data,
            ...{ propertyId: this.propertyId }
          }
        );
      } else if (event == "editSetRate") {
        this.setPrices({
          setRate: this.params?.data?.spacegroup_set_rate != null ? this.params?.data?.spacegroup_set_rate : '',
          sellRate: null,
          spaceGroupInfo: {...this.params.data},
          propertyId: this.propertyId
        });
      } else if (event == "editSellRate") {
        this.setPrices({
          sellRate: this.params?.data?.spacegroup_sell_rate != null ? this.params?.data?.spacegroup_sell_rate : '',
          setRate: null,
          spaceGroupInfo: {...this.params.data},
          propertyId: this.propertyId
        });
      } else {
        EventBus.$emit("rateManagementByPropertyEvent", {
          event,
          data: this.params.data,
        });
      }
    },
  },
};
</script>
    
<template>
  <div>
    <!-- Populate Menu items -->
    <v-card class="mx-auto" max-width="500">
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            @click="triggerEvent(item.event)"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <!-- Populate Menu items -->
  </div>
</template>
    