<template>
    <div class="overview-panel px-1">
        <div class="overview-container" v-if="retireStatus">
            <div class="slide-out-section">
                <div class="mb-5">
                    <v-expansion-panels multiple v-model="activePanel">
                        <hb-expansion-panel>
                            <template v-slot:title>
                                <span class="hb-font-body-medium">Retire All Leads</span>
                            </template>
                            <template v-slot:content>
                                <hb-card>
                                    <div>
                                        <!-- Reason Field -->
                                        <hb-form label="Reason" required>
                                            <v-select
                                                hide-details
                                                :items="reasons"
                                                label="Choose Reason"
                                                single-line
                                                v-validate="'required'"
                                                id="lead_reason"
                                                name="lead_reason"
                                                data-vv-as="Reason"
                                                data-vv-scope="retire_lead_interactions"
                                                v-model="retire.reason"
                                                @blur="handleBlur"
                                            ></v-select>
                                        </hb-form>

                                        <!-- Lead Interactions -->
                                        <hb-form label="Lead Interactions" required>
                                            <v-combobox
                                                hide-details
                                                label="Select lead interactions"
                                                single-line
                                                :items="interactions"
                                                item-value="id"
                                                item-text="label"
                                                v-validate="'required'"
                                                id="retire_lead_interactions"
                                                name="retire_lead_interactions"
                                                data-vv-as="Lead Interactions"
                                                multiple
                                                clearable
                                                clear-icon="mdi-close-circle"
                                                v-model="dynamicSelect"
                                                :menu-props="{ contentClass: 'hb-elevation-large reports-property-selector-combobox-menu'}"
                                                :filter="filterInteractions"
                                                :hide-no-data="!searchInteraction"
                                                :search-input.sync="searchInteraction"
                                                data-vv-scope="retire_lead_interactions"
                                                @blur="handleBlur"
                                            >
                                                <template v-slot:selection="{ item, parent }">
                                                    <hb-chip
                                                        @click="parent.selectItem(item)"
                                                        editable
                                                    >
                                                        <span v-if="item.unit_no">{{ item.unit_no }} - </span>
                                                        <span v-if="item.property_name">{{ item.property_name }}</span>
                                                    </hb-chip>
                                                </template>

                                                <template v-slot:prepend-item>
                                                    <v-list-item @click="toggleSelectAll" class="select-all-item">
                                                        <v-list-item-action>
                                                            <v-icon :color="icon == 'check_box' || dynamicSelect.length > 0 ? '#00848E' : '#dfe3e8'">{{ selectAllIcon }}</v-icon>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Select All</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                        </hb-form>

                                        <!-- Opt-Out Checkbox -->
                                        <hb-form label="Opt-Out">
                                            <v-checkbox
                                                label="Remove tenant from all future communication."
                                                solo
                                                flat
                                                hide-details
                                                single-line
                                                v-model="retire.opt_out"
                                                id="lead_OptOut"
                                                name="lead_OptOut"
                                                data-vv-as="opt"
                                                class="pa-0 mt-0"
                                            ></v-checkbox>
                                        </hb-form>

                                        <!-- Notes for Retiring -->
                                        <hb-form label="Notes for Retiring Leads" required>
                                            <v-textarea
                                                hide-details
                                                auto-grow
                                                flat
                                                rows="6"
                                                label="Why are you retiring these leads?"
                                                single-line
                                                v-model="retire.notes"
                                                v-validate="'required'"
                                                id="retire_reason"
                                                name="retire_reason"
                                                data-vv-as="Notes"
                                                class="pa-0 mt-0"
                                                data-vv-scope="retire_lead_interactions"
                                                @blur="handleBlur"
                                            ></v-textarea>
                                        </hb-form>

                                        <!-- Bottom Action Bar -->
                                        <hb-bottom-action-bar @close="cancelRetireInteractions" :top-border="false">
                                            <template v-slot:right-actions>
                                                <hb-btn
                                                    v-if="hasPermission('retire_lead')"
                                                    color="destructive"
                                                    @click="retireLead"
                                                >
                                                    Retire All Leads
                                                </hb-btn>
                                            </template>
                                        </hb-bottom-action-bar>
                                    </div>
                                </hb-card>
                            </template>
                        </hb-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';
    import DropdownMenu from '../assets/DropdownMenu.vue';
    import HbDatePicker from '../assets/HummingbirdDatepicker';

    import { EventBus } from '../../EventBus.js';
    import Autocomplete from '../assets/Autocomplete.vue';
    import { mapGetters, mapActions } from 'vuex';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import LEASE from '@/constants/lease.js'; 
    import interactions from '../../assets/api/interactions.js';
    
    export default {
        name: "RetireLeadInteractions",
        mixins: [notificationMixin],
        data() {
            return {
                reasons: [],
                interactions: [],
                retire: {
                    opt_out: false,
                    notes: '',
                    reason: '',
                    lead_ids: []
                },
                leads: [],
                searchInteraction: '',
                dynamicSelect: [],
                selectAllIcon: 'mdi-checkbox-blank-outline',
                activePanel: [0], // Set to an array to keep the first panel open by default
            }
        },

        async created() {
            this.getRetireReasons();
            this.getLeads();
        },
        beforeDestroy() {},
        components: {
            Loader,
            Status,
            Dropdown,
            DropdownMenu,
            Autocomplete,
            HbDatePicker
        },
        methods: {
            async retireLead() {
                let retire_lead_interaction_status = await this.$validator.validateAll('retire_lead_interactions');

                if (!retire_lead_interaction_status) {
                    this.showMessageNotification({ description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                }

                let selectedInteractionIds = this.dynamicSelect.map(item => item.id);
                let ids = selectedInteractionIds.map(i=>i)

                this.retire.lead_ids = ids;

                try {

                    await api.post(this, api.LEADS + 'retire-lead-interactions', this.retire);

                    EventBus.$emit('lead_saved');
                    this.$emit('lead_saved');
                    this.cancelRetireInteractions();
                    this.getLeads();
                    let message = 'Leads retired successfully';
                    this.showMessageNotification({ type: 'success', description: message });
                } catch (error) {
                    this.showMessageNotification({ type: 'error', description: error.message || 'An error occurred while retiring the lead interactions.' });
                }
            },
            getRetireReasons() {
                api.get(this, api.LEADS + 'retire-reasons').then(r => {
                    this.reasons = r;
                });
            },
            async getLeads() {
                let leads_data = await api.get(this, api.CONTACTS + this.contact_id + '/leads');
                this.leads = leads_data.leads.filter(lead => lead.status === 'active');

                this.cancelRetireInteractions();   

                this.interactions = this.leads.map(lead => ({
                    id: lead.id,
                    type: lead.lead_type,
                    unit_no: lead.Unit.number,
                    property_name: lead.Property.name,
                    label: `${lead.lead_type || 'Lead'} ${lead.Unit?.number ? `| #${lead.Unit.number}` : ''} | ${lead.Property.name}`
                }));
            },
            filterInteractions(item, queryText, itemText) {
                const name = item.label.toLowerCase();
                const searchText = queryText.toLowerCase();
                return name.indexOf(searchText) > -1;
            },
            toggleSelectAll() {
                if (this.dynamicSelect.length === this.interactions.length) {
                    this.dynamicSelect = [];
                } else {
                    this.dynamicSelect = this.interactions.map(item => item);
                }
            },
            updateSelectAllIcon() {
                if (this.dynamicSelect.length === 0) {
                    this.selectAllIcon = 'mdi-checkbox-blank-outline';
                } else if (this.dynamicSelect.length === this.interactions.length) {
                    this.selectAllIcon = 'mdi-checkbox-marked';
                } else {
                    this.selectAllIcon = 'mdi-minus-box';
                }
            },
            clearSelections() {
                this.retire.reason='';
                this.dynamicSelect = [];
                this.retire.notes='';
            },
            cancelRetireInteractions(){
                this.retireStatus = false;
                this.$emit('update:retireStatus', this.retireStatus);
            },
            handleBlur(event) {
                this.$validator.reset();
            },
        },
        computed: {
            icon() {
                return this.dynamicSelect.length > 0 ? 'check_box' : 'check_box_outline_blank';
            }
        },
        props: {
            retireStatus: {
                type: Boolean
            },
            contact_id: {
                type: String
            }
        },
        watch: {
            retireStatus(newStatus) {
                this.activePanel = [0]; // Set to active (expanded) when retireStatus changes
                this.clearSelections();
            },
            contact_id(newId) {
                if (newId) {
                    this.getLeads();
                    this.activePanel = [0]; // Ensure the panel is expanded when contact_id changes
                }
            },
            dynamicSelect() {
                this.updateSelectAllIcon();
            }
        }
    }
</script>

<style scoped>
    .overview-container {
        flex: 1 0 0;
    }
    .slide-out-section {
        padding: 18px 24px 4px;
    }
    .overview-panel {
        display: flex;
        align-items: stretch;
        background: #F9FAFB;
    }

    /* Custom styles for the Select All item to make it consistent with other checkboxes */
    .select-all-item .v-list-item-action .v-icon {
        font-size: 20px; /* Adjust the size to match other checkboxes */
        margin-left: 0;  /* Remove extra margin */
    }
    .select-all-item .v-list-item-title {
        margin-left: 0; /* Align title with checkbox */
    }

    /* Style for the Clear Selections button */
    .clear-selection-btn {
        margin-top: 10px;
    }
</style>
