<template>
  <div class="editor-container pb-0">
    <v-card flat style="height: 100%; overflow: auto">
      <div>
        <status
          @resetStatus="errorClear(template)"
          v-if="errorHas(template)"
          :message="errorGet(template)"
          status="error"
        ></status>
        <status
          @resetStatus="successClear(template)"
          v-if="successHas(template)"
          :message="successGet(template)"
          status="success"
        ></status>
        <p class="font-regular mt-2 px-5">
          {{ getDescriptionText }}
        </p>
      </div>
      <div>
        <hb-form v-if="showDownload" label="Download Report">
          <div>
            <hb-btn color="primary" class="mt-4" @click="downloadFile">
              Download
            </hb-btn>
          </div>
          <div class="mt-4 mb-0">
            <span class="hb-text-night-light">
              {{ getFileName }}
            </span>
          </div>
          <div v-if="lastDownload" class="mt-1">
            <span class="hb-text-night-light">
              {{ lastDownload }}
            </span>
          </div>
        </hb-form>
        <hb-form v-if="showUpload" full label="Upload Report">
          <div class="hb-text-night-light justify-hint-text">
            <span class="hb-font-body-medium unset-line-height">Note:</span>
            Ensure the uploaded XLSX file is complete before upload. 
            Any changes post upload must be made within Hummingbird. 
            Modifying the file name will lead to incorrect uploads.
          </div>
          <div class="my-4">
            <HbFileInput v-model="file" placeholder="Select XLSX file" />
          </div>
          <hb-btn
            color="primary"
            class="mt-4"
            @click="initiateUpload"
            :disabled="!file"
            :loading="uploading"
          >
            Upload
          </hb-btn>
          <div v-if="lastUpload" class="mt-4">
            <span class="hb-text-night-light">
              {{lastUpload}}
            </span>
          </div>
        </hb-form>
        <hb-modal
          v-model="showNoPermissionModal"
          size="medium"
          title="Unable to Upload Rent Changes"
          :footerOff="true"
          confirmation
          @close="clearRowRentChangeId()"
          show-help-link
        >
          <template v-slot:content>
            <div class="px-6 py-4">
              <div class="pb-2">
                The signed in account does not have permission to upload rent
                changes. To upload rent changes, please contact your
                administrator.
              </div>
            </div>
          </template>
        </hb-modal>
      </div>
    </v-card>

    <ConfirmationPopup v-once ref="confirmationPopup" />
  </div>
</template>

<script type="text/babel">
import Status from "../Messages.vue";
import api from "../../../assets/api.js";
import moment from "moment";
import { mapGetters } from "vuex";
import Vue from "vue";
import { cloneDeep } from "lodash";
import { EventBus } from '../../../EventBus.js';
import { downloadFile as downloadFileFromResponse } from "../../../utils/common.js";

import ConfirmationPopup from "@/components/revenue_management/utils/ConfirmationPopup.vue";
export default {
  name: "DownloadUpload",
  data() {
    return {
      file: null,
      uploading: false,
      uploadedFileName: null,
      showNoPermissionModal: false
    };
  },
  components: {
    Status,
    ConfirmationPopup,
  },

  props: ["actions_panel", "template", "selected"],

  computed: {
    ...mapGetters({
      getDynamicRunParam: "reportStore/geDynamicRunParam",
      getDefaultSearchFilter: "reportStore/getDefaultSearchFilter",
      getRentChangeUploadHistory: "reportStore/getRentChangeUploadHistory",
      properties: "propertiesStore/properties",
      selectedProperty: "revManStore/getSelectedProperty",
      getCompany: "authenticationStore/getCompany",
      hasRolePermission: "authenticationStore/rolePermission",
    }),
    /**
     * Add report spcific data to the below object.
     */
    reportSpecificData() {
      return (
        {
          review_rent_changes: {
            description: "upcoming rent change reports",
            fileName: this.getReviewRentChangeReportName,
            uniqueIdField: "lease_id",
            propertyIdField: "rentchange_property_id",
            apiSubmition: "initiateUploadRentChange",
          },
        }[this.template] ?? {
          description: "this report",
          fileName: "HB_Report",
          uniqueIdField: "",
          apiSubmition: "",
        }
      );
    },

    deploymentMonth() {
      return this.getDefaultSearchFilter.filter?.[0].value
    },

    formattedDeploymentMonth() {
      return moment(this.deploymentMonth, "MMM YYYY").isValid() ?
        moment(this.deploymentMonth, "MMM YYYY").format("YYYY_MM") : "";
    },
    getReviewRentChangeReportName() {
      let propertyDetails = this.properties.find(
        (property) => property.id === this.selectedProperty
      );
      return `${propertyDetails.name.replaceAll(
        " ",
        "_"
      )}_rent_changes_${this.formattedDeploymentMonth}`;
    },
    getFileName() {
      return `${this.reportSpecificData.fileName}.xlsx`;
    },
    getDescriptionText() {
      let status = this.showDownload
        ? this.showUpload
          ? "Download and Upload"
          : "Download"
        : "Upload";
      return `${status} ${this.reportSpecificData.description}.`;
    },
    showDownload() {
      return this.actions_panel.includes("download");
    },
    showUpload() {
      return this.actions_panel.includes("upload");
    },
    hasUploadPermission() {
      return this.hasRolePermission("manual_rent_changes");
    },
    lastDownload() {
      return this.getLastActionInfo("download")
    },
    lastUpload() {
      return this.getLastActionInfo("upload")
    }
  },

  methods: {
    /**
     * Upload confirmation pop up
     */
    async showManualConfirmationPopup() {
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: `Confirm Upload`,
          message: `You are about to upload <strong>${
            this.uploadedFileName ?? this.getFileName
          }</strong> to this report.<br/><br/>Are you sure you want to continue?`,
          buttonType: "primary",
          resolver: `Continue`,
        })
        .catch(() => false);
      if (confirmed) {
        this.uploading = true;
        this[this.reportSpecificData.apiSubmition]();
      }
    },

    appendAsterisks(data, editableFields) {
      data.columns.forEach((item) => {
        if (editableFields.includes(item.key)) {
          item['label'] = `${item['label']}*`
        }
      })
    },

    async downloadFile() {
      let selectedReport = cloneDeep(this.selected);
      let { limit, offset, ...filters } = selectedReport.filters;
      if(this.selected?.template === 'review_rent_changes') {
        let alterFields = [
          "rentchange_current_rent_variance",
          "rentchange_new_rent_variance",
          "rentchange_rent_variance_combined",
          "tenant_rent_plan_id",
          "rentchange_effective_date"
        ]
        filters.search['rent_change_deployment_month'] = this.deploymentMonth
        let leaseColIndex = filters.columns.findIndex(col => col.key === "lease_id")
        filters.columns.unshift(filters.columns.splice(leaseColIndex, 1)[0]);
        alterFields.forEach(col => {
          let index = filters.columns.findIndex(data => data.key === col)
          if (col === "rentchange_effective_date") filters.columns[index].label = "Effective Date (MM/DD/YYYY)"
          else filters.columns.splice(index, 1)
        })
      }
      let editable_fields = ['rentchange_effective_date', 'rentchange_new_rent', 'lease_id', 'rentchange_property_id']
      this.appendAsterisks(filters, editable_fields)
      filters.columns.forEach((item) => {
        if (item.key == "rentchange_recent_note") item['width'] = item.label.length + 20
        else item['width'] = item.label.length + 5
      })
      let url = api.REPORTS + "generate";
      let data = {
        filters,
        type: this.selected.template,
        format: "xlsx",
        name: this.getFileName,
        filename: this.getFileName?.split('.').slice(0, -1).join('.'),
        custom_config: {
          hide_count_row: true,
          add_style: true
        }
      };
      if(Object.keys(this.getDynamicRunParam).length && this.$route.query.dynamicRun) {
        data['properties'] = this.getDynamicRunParam.propertyIDArray;
      }

      try {
        let response = await this.$http.post(url, data);
        EventBus.$emit('fetch_upload_download_history');
        downloadFileFromResponse({
          content_type: response.headers.get('content-type'),
          file: response.data,
          file_name: this.getFileName,
          url: response.data?.data?.url
        });
      } catch (error) {
        console.log("Download Error: ", error)
      }
    },

    async initiateUpload() {
      if (this.hasUploadPermission) {
        let self = this;
        let regex = /^([a-zA-Z0-9\s_\\.\-:\(\)])+(.xlsx)$/;
        this.uploadedFileName = self.file[0].name;
        if (regex.test(self.file[0].name.toLowerCase())) {
          this.showManualConfirmationPopup();
        } else {
          this.setError("invalid");
        }
      } else {
        this.showNoPermissionModal = true;
      }
    },

    async initiateUploadRentChange() {
      let file = this.file[0];
      let data = {
        file_name: file.name,
        file_type: "rent_management",
        file_size: file.size,
        attributes: {
          document_type: file.type,
        },
      };
      let headers = {
        "X-storageapi-date": moment().unix().toString(),
      };
      try {
        let fileAppId = process.env.VUE_APP_FILE_APP_ID;
        let baseUrl = process.env.VUE_APP_GDS_BASE_URL;
        let response = await Vue.http.post(
          `${baseUrl}/v3/applications/${fileAppId}/v1/owners/${this.getCompany.gds_owner_id}/files/`,
          data,
          { headers }
        );
        let credentials = response.body.applicationData[fileAppId][0].data;
        await this.uploadFiletoAws(credentials, file);
        await this.uploadRentChanges(credentials.id);
        this.uploading = false;
        this.file = null;
      } catch (error) {
        this.setError();
        console.log("Rent change upload failed", error);
      }
    },

    async uploadFiletoAws(credentials, file) {
      function updateFormData() {
        const formData = new FormData();
        let uploadFields = credentials?.upload_fields;
        for (let field in uploadFields) {
          formData.append(field, uploadFields[field]);
        }
        formData.append("Content-Type", file.type);
        formData.append("file", file);
        return formData;
      }
      let formDataContent = updateFormData();
      await Vue.http.post(credentials?.upload_url, formDataContent, {
        before: (request) => delete request.headers.map["authorization"],
      });
    },

    async uploadRentChanges(documentId) {
      let url = `${api.getPropertyRentManagementUrl(
        this.selectedProperty
      )}rent-change/upload`;
      let body = {
        document_id: documentId,
        template: this.template,
        deployment_month: this.deploymentMonth
      };
      await api.post(this, url, body);
    },

    getLastActionInfo(action) {
      let actionType = action === "download" ? "Downloaded" : "Uploaded"
      let lastAction = ""
      if (this.getRentChangeUploadHistory[action]?.action_at) {
        lastAction = `Last ${actionType} On: ${moment(
          this.getRentChangeUploadHistory[action].action_at, 'YYYY-MM-DD HH:mm:ss'
        ).format('MMM DD, YYYY')} by ${this.getRentChangeUploadHistory[action].action_by}`
      }
      return lastAction;
    },

    setError(type) {
      let message = "";
      switch (type) {
        case "invalid":
          message = "Please upload a valid XLSX file.";
          break;
        default:
          message = "Oops! Something went wrong!";
      }
      this.errorSet(this.template, message);
      this.file = null;
      this.uploading = false;
    },
  },
};
</script>

<style scoped>
.justify-hint-text {
  text-align: justify;
  text-justify: inter-word;
}
.unset-line-height {
  line-height: unset;
}
</style>